const route = [
  '$stateProvider',
  ($stateProvider) => {
    $stateProvider.state('ppt-presen', {
      url: '/',
      component: 'pptCreator',
    });
  },
];

export { route as default };

