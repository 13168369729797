import tileTemplate from './preview-tile.tpl.html';

export class PreviewTile {
  constructor($state, $scope, $transitions, $rootScope, $sce) {
    Object.assign(this, {
      $state,
      $scope,
      $transitions,
      $rootScope,
      $sce,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  $onInit() {
    this.canvas = this.$sce.trustAsHtml(this.tile.canvas);

    // this.$rootScope.$on('ppt-preview:created', (evt, tile) => {
    //   this.tile = [];
    //   Object.assign(this.tile, tile);
    // });

    // this.$rootScope.$on('ppt-preview:updated', (evt, tile) => {
    //   Object.assign(this.tile, tile);
    // });
  }

  selectSlide() {
    console.log('selectSlide', this.tile);
    this.$rootScope.$emit('ppt-slide:changed', this.tile);
  }
}

export const tile = {
  template: tileTemplate,
  controller: [
    '$state',
    '$scope',
    '$transitions',
    '$rootScope',
    '$sce',
    PreviewTile,
  ],
  bindings: {
    tile: '<',
  },
};
