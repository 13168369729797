function httpInterceptor($state, $cookies) {
  Object.assign(this, {
    $state,
  });

  return {
    request: (config) => {
      config.headers = config.headers || {};
      if ($cookies.get('jwt_token')) {
        config.headers.Authorization = `Bearer ${$cookies.get('jwt_token')}`;
      }
      return config;
    },

    requestError: config => config,

    responseError: (res) => {
      if (res.status === 401) {
        $state.go('login');
      }
      return Promise.reject(res);
    },
  };
}


export default httpInterceptor;

