import angular from 'angular';
import '@uirouter/angularjs';
import 'angular-cookies';
import 'angular-sanitize';

import httpInterceptor from './interceptors/httpInterceptor';
import presentation from './services/presentation.service';
import googleImages from './services/google-images.service';
import pptPresentationService from './services/ppt-presentation.service';

import './modules/creator';
import './modules/bot';
import './modules/slide';
import './modules/preview';
import { homedir } from 'os';
import preview from './services/preview.service';

const routes = [
  '$urlRouterProvider',
  ($urlRouterProvider) => {
    $urlRouterProvider.otherwise('/');
  },
];

angular.module('pptbot',
  [
    'pptbot.ppt-creator',
    'pptbot.ppt-bot',
    'pptbot.ppt-slide',
    'pptbot.ppt-preview',
    'ui.router',
    'ngCookies',
    'ngSanitize',
  ])
  .config(routes)
  .factory('httpInterceptor', ['$state', httpInterceptor])
  .config(
    ['$httpProvider',
      ($httpProvider) => {
        $httpProvider.interceptors.push('httpInterceptor');
      }])
  .factory('googleImages', [googleImages])
  .factory('pptPresentationService', [pptPresentationService])
  .factory('preview', [
    '$rootScope',
    '$q',
    preview,
  ])
  .factory('presentation', [
    '$rootScope',
    'googleImages',
    'pptPresentationService',
    presentation,
  ])
  // .directive('resize', ['$window',
  //   ($window) => {
  //     return {
  //       link,
  //       restrict: 'E',
  //       template: '<div>window size: {{width}}px</div>',
  //     };

  //     function link(scope, element, attrs) {
  //       const height = $element[0].offsetHeight;
  //       const ratio = (7.5 / 13.3);
  //       const width = height * ratio;
  //       $element.css('width', `${width}px`);
  //     }
  //   },
  // ])
  .directive('resize', [
    () => ({
      link: ($scope, $element, $attributes) => {
        // const scale = Math.min(
        //   availableWidth / contentWidth,
        //   availableHeight / contentHeight
        // );
        // const h = 750;
        // const w = 1330;
        // $element.css('width', `${w}px`);
        // $element.css('height', `${h}px`);
        // const height = $element[0].offsetHeight;
        // const ratio = (7.5 / 13.3);
        // const width = height * ratio;
        // // $element.css('width', `${width}px`);
        // $element.css('transform', `translate(-50%, -50%) scale(${ratio})`);
        // 1074*661 = 1920*1080
        // 1074 : 661 = $element[0].offsetWidth = $element[0].offsetHeight
        // const ratio = $element[0].offsetWidth / 1074;
        // $element.css('transform', `scale(${ratio})`);

      },
    })]);
