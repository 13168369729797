import PptxGenJS from 'pptxgenjs';

export default function pptPresentationService() {
  const presentationTitle = '';
  function download(slides) {
    console.log('Slides: ', slides);

    const pptx = new PptxGenJS();
    pptx.defineSlideMaster({
      title: 'PRESENTATION_MODEL',
      bkgd: 'FFFFFF',
      objects: [
      ],
      // slideNumber: { x:0.3, y:'90%' }
    });
    pptx.setLayout('LAYOUT_WIDE');

    // eslint-disable-next-line camelcase
    const logo_h = 0.67;
    // eslint-disable-next-line camelcase
    const logo_y = 0.5;
    // eslint-disable-next-line camelcase
    const logo_w = (logo_h * 158) / 51;// 2.3;
    // eslint-disable-next-line camelcase
    const logo_x = 12.6 - logo_w;
    const logoImg = '/assets/images/logo-one.png';

    // eslint-disable-next-line camelcase
    const logoCustom_h = 1.435;
    // eslint-disable-next-line camelcase
    const logoCustom_y = logo_y + logo_h;
    // eslint-disable-next-line camelcase
    const logoCustom_w = (logoCustom_h * 85) / 80;// 2.3;
    // eslint-disable-next-line camelcase
    const logoCustom_x = 12.6 - logoCustom_w;
    // eslint-disable-next-line camelcase

    pptx.setAuthor('Riccardo Agnoletto');
    pptx.setCompany('H-Farm');
    pptx.setRevision('1');
    pptx.setSubject('Presentation');
    pptx.setTitle('Presentation generated with PPTBot');

    slides.forEach((page) => {
      if (page.type === 'basic') {
        const slide = pptx.addNewSlide('PRESENTATION_MODEL');
        this.presentationTitle = page.title;
        slide.addImage(
          {
            x: logo_x, y: logo_y, w: logo_w, h: logo_h, path: logoImg,
          },
        );
        slide.addImage(
          { x: logoCustom_x, y: logoCustom_y, w: logoCustom_w, h: logoCustom_h, type: 'contain', data: page.imagedata },
        );

        slide.addText(
          [
            { text: this.presentationTitle.toUpperCase(), options: { fontSize: 59, fontFace: 'adineue PRO TT', color: '000000', align: 'l', bold: true, breakLine: true, lineSpacing: 45, paraSpaceAfter: 10 } }, { text: page.subtitle, options: { fontSize: 27, fontFace: 'AdiHaus PS', color: '000000', align: 'l' } },
          ],
          { x: 0.5, y: 2.5, w: 11, h: 2.0 },
        );
        slide.showPageNumber = false;
      } else if (page.type === 'inspirational') {
        const slideInspirational = pptx.addNewSlide('PRESENTATION_MODEL');
        slideInspirational.addImage(
          {
            x: 0,
            y: 0,
            w: '100%',
            h: '100%',
            path: 'assets/images/inspiration-bg.jpg',
            sizing: { type: 'cover', w: '100%', h: '100%' },
          },
        );
        slideInspirational.addText(
          [
            { text: `"${page.text}'`, options: { fontSize: 29, fontFace: 'AdiHaus PS', color: 'FFFFFF', align: 'c', bold: true, breakLine: true, lineSpacing: 50, paraSpaceAfter: 15, valign: 'middle' } },
          ],
          { x: '24%', y: 0.5, w: '52%', h: 7.0 },
        );

        // const slideNumber = slideInspirational.getPageNumber();
        // slideInspirational.addText(
        //   [
        //     { text: `${this.presentationTitle}  ${slideNumber}`, options: { fontSize: 10, color: 'FFFFFF', align: 'r', bold: true, breakLine: false, lineSpacing: 10, paraSpaceAfter: 0, valign: 'bottom' } },
        //   ],
        //   { x: 0.5, y: 6.9, w: 12.5, h: 0.5 },
        // );
      } else if (page.type === 'textual') {
        const slideContent = pptx.addNewSlide('PRESENTATION_MODEL');

        slideContent.addText(
          [
            { text: page.title.toUpperCase(), options: { fontSize: 57, fontFace: 'adineue PRO', color: '000000', align: 'l', bold: false, breakLine: true, lineSpacing: 45, valign: 'top' } }, { text: page.text, options: { fontSize: 13.4, fontFace: 'AdiHaus PS', color: '000000', align: 'l', bold: false, lineSpacing: 15, valign: 'top', paraSpaceBefore: 25, isTextBox: true } },
          ],
          { x: 0.5, y: 3, w: 12.3, h: 3.9, valign: 'top' },
        );
        if (page.defaultLogo) {
          slideContent.addImage(
            {
              x: logo_x, y: logo_y, w: logo_w, h: logo_h, path: logoImg,
            },
          );
        }
        
        // const slideNumber = slideContent.getPageNumber();
        // slideContent.addText(
        //   [
        //     { text: `${this.presentationTitle}  ${slideNumber}`, options: { fontSize: 10, color: '000000', align: 'r', bold: true, breakLine: false, lineSpacing: 10, paraSpaceAfter: 0, valign: 'bottom' } },
        //   ],
        //   { x: 0.5, y: 6.9, w: 12.5, h: 0.5 },
        // );
      }
    });
    pptx.save('Presentation');

    return true;
  }
  const service = {
    download,
  };
  return service;
}
