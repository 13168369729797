import slidetemplate from './slide.tpl.html';

export class Slide {
  constructor($state, $scope, $transitions, $rootScope, $window) {
    Object.assign(this, {
      $state,
      $scope,
      $transitions,
      $rootScope,
      $window,
    });
    this.slide = {};
    this.$scope = $scope;
    this.appWindow = angular.element($window);
    this.width = 0;
    this.height = 0;
  }

  $onInit() {
    this.$rootScope.$on('ppt-slide:created', (evt, slide) => {
      this.slide = {};
      Object.assign(this.slide, slide);
    });

    this.$rootScope.$on('ppt-slide:updated', (evt, slide) => {
      Object.assign(this.slide, slide);
    });

    this.$rootScope.$on('ppt-slide:changed', (evt, slide) => {
      this.slide = {};
      Object.assign(this.slide, slide);
    });

    const appWindow = angular.element(this.$window);

    appWindow.bind('resize', () => {
      // 13.3 x 7.5 = w x h
      // 13.3 x 7.5 = 1342 x h =>
      // w = 1952 - 1342
      const h = document.getElementsByClassName('ppt-slide')[0].offsetHeight;
      // eslint-disable-next-line no-unused-expressions
      this.width = (13.3 * h) / 7.5;
      console.log(`${this.$window.innerWidth} ${this.$window.innerHeight}`);
    });
  }
}

export const slide = {
  template: slidetemplate,
  controller: [
    '$state',
    '$scope',
    '$transitions',
    '$rootScope',
    '$window',
    Slide,
  ],
};
