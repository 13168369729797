import cf from 'conversational-form';
import bottemplate from './bot.tpl.html';

export class Bot {
  constructor($state, $scope, $transitions, $rootScope, $timeout) {
    Object.assign(this, {
      $state,
      $scope,
      $transitions,
      $rootScope,
      $timeout,
    });
    this.cfForm = {};
    this.show = false;
  }

  $onInit() {
    this.message = 'Welcome to the bot MODULE!';
    const flowCallback = (dto) => {
      console.log('dto', dto);
      this.$timeout(() => {
        this.$rootScope.$emit('ppt-bot:answered', {
          tagName: dto.tag.name,
          value: dto.tag.value,
        }, 100);
      });

      if (typeof dto.tag.value[0] !== 'undefined' && dto.tag.value[0].indexOf('http') > -1) {
        window.ConversationalForm.addUserChatResponse(`<img src="${dto.tag.value}">`);
      } else {
        window.ConversationalForm.addUserChatResponse(dto.tag.value);
      }
      const domel = angular.element(document.querySelector('cf-input-control-elements'));
      domel.addClass('hidden');
      // window.ConversationalForm.remapTagsAndStartFrom(window.ConversationalForm.tags.length);
      // success();
    };

    this.$rootScope.$on('ppt-bot:create', () => {
      if (window.ConversationalForm) {
        console.warn('Bot already existing, RESTARTING');
        destroy();
      }
      istantiate();
      this.$rootScope.$emit('ppt-bot:created');
    });


    this.$rootScope.$on('ppt-bot:retoric', (evt, data) => {
      const domel = angular.element(document.querySelector('cf-input-control-elements'));
      domel.addClass('hidden');
      window.ConversationalForm.addRobotChatResponse(data.question);
      window.ConversationalForm.addUserChatResponse(data.answer);
    });

    this.$rootScope.$on('ppt-bot:speak-bot', (evt, data) => {
      const domel = angular.element(document.querySelector('cf-input-control-elements'));
      domel.addClass('hidden');
      window.ConversationalForm.addRobotChatResponse(data.message);
    });

    this.$rootScope.$on('ppt-bot:ask', (evt, data) => {
      console.debug(data);

      const tagName = data.name;
      const question = data.question;

      const questionTemplate = {
        tag: 'input',
        type: 'text',
        name: tagName,
        'cf-questions': question,
      };
      window.ConversationalForm.addTags([questionTemplate], true);
      window.ConversationalForm.remapTagsAndStartFrom(window.ConversationalForm.tags.length - 1);
    });

    this.$rootScope.$on('ppt-bot:ask-multiple', (evt, data) => {
      console.debug(data);
      const domel = angular.element(document.querySelector('cf-input-control-elements'));
      domel.removeClass('hidden');
      const isMultipleEnabled = !!data.multiple;
      const tagName = data.name;
      const question = data.question;
      const children = data.answers;
      const placeholder = data.placeholder ? data.placeholder : 'Type to filter the choises';
      const childrenArray = children.map(element => ({
        tag: 'option',
        'cf-label': element,
        type: (isMultipleEnabled ? 'checkbox' : 'radio'),
        value: element,
      }));
      const questionTemplate = {
        tag: 'select',
        name: tagName,
        'cf-questions': question,
        'cf-input-placeholder': placeholder,
        children: childrenArray,
      };
      window.ConversationalForm.addTags([questionTemplate], true);
      window.ConversationalForm.remapTagsAndStartFrom(window.ConversationalForm.tags.length - 1);
      // window.ConversationalForm.remapTagsAndStartFrom(this.messages);
    });

    this.$rootScope.$on('ppt-bot:ask-images', (evt, data) => {
      console.debug(data);
      const domel = angular.element(document.querySelector('cf-input-control-elements'));
      domel.removeClass('hidden');
      const isMultipleEnabled = !!data.multiple;
      const tagName = data.name;
      const question = data.question;
      const children = data.answers;
      const placeholder = data.placeholder ? data.placeholder : 'Type to filter the choices';
      const childrenArray = children.map(element => ({
        tag: 'option',
        'cf-image': element,
        type: (isMultipleEnabled ? 'checkbox' : 'radio'),
        value: element,
      }));
      const questionTemplate = {
        tag: 'select',
        name: tagName,
        'cf-questions': question,
        'cf-input-placeholder': placeholder,
        children: childrenArray,
      };
      window.ConversationalForm.addTags([questionTemplate], true);
      window.ConversationalForm.remapTagsAndStartFrom(window.ConversationalForm.tags.length - 1);
    });

    this.$rootScope.$on('ppt-bot:destroy', (evt, data) => {
      console.log('enabling bot', data);
      reveal(false);
      this.show = false;
      this.cfForm = {};
    });

    this.$rootScope.$on('ppt-bot:hide', () => {
      reveal(false);
      this.show = false;
    });

    this.$rootScope.$on('ppt-bot:show', () => {
      reveal(true);
      this.show = true;
      window.ConversationalForm.focus();
    });

    this.$rootScope.$on('ppt-bot:toggle', () => {
      reveal(this.show);
      this.show = !this.show;
    });

    function reveal(show) {
      const domel = angular.element(document.querySelector('.bot'));
      const activeDomEl = angular.element(document.querySelector('#bot-icon'));
      if (show) {
        domel.removeClass('hiddenbot');
        activeDomEl.addClass('bot-active');
      } else {
        domel.addClass('hiddenbot');
        activeDomEl.removeClass('bot-active');
      }
    }

    function istantiate() {
      console.log('enabling bot');
      // creating the bot istance on window.ConversationalForm
      cf.startTheConversation({
        formEl: document.getElementById('cf-form'),
        context: document.getElementById('cf-context'),
        preventAutoFocus: false,
        flowStepCallback: flowCallback,
        loadExternalStyleSheet: 'styles/main.css',
        userImage: 'IA==',
        robotImage: '/assets/images/bot.png',
        // submitCallback: finalResponse(),
      });
      window.ConversationalForm.remapTagsAndStartFrom();
    }

    function destroy() {
      window.ConversationalForm.remove();
      // this.messages = 0;
    }
  }
}

export const bot = {
  template: bottemplate,
  controller: [
    '$state',
    '$scope',
    '$transitions',
    '$rootScope',
    '$timeout',
    Bot,
  ],
};
