import previewTemplate from './preview.tpl.html';

export class Preview {
  constructor($state, $scope, $transitions, $rootScope, $preview) {
    Object.assign(this, {
      $state,
      $scope,
      $transitions,
      $rootScope,
      $preview,
    });
    this.slidePreview = [];
  }

  $onInit() {
    this.slidePreview = [];
    this.$rootScope.$on('ppt-slide:created', (evt, slide) => {
      this.$preview.createPreview(slide);
    });

    this.$rootScope.$on('ppt-slide:updated', (evt, slide) => {
      setTimeout(() => {
        this.$preview.updatePreview(slide);
      }, 1000);
    });

    this.$rootScope.$on('ppt-preview:created', (evt, preview) => {
      this.slidePreview.push(preview);
      // document.querySelector('#preview-wrapper').scrollLeft = 100000;
      angular.element(document.querySelector('#preview-wrapper'))[0].scrollLeft = 100000;
    });

    this.$rootScope.$on('ppt-preview:updated', (evt, slidePreview) => {
      this.updateCurrentPreview(slidePreview);
    });

    this.$rootScope.$on('ppt-preview:addPreview', (evt, slide) => {
      if (this.slidePreview.length === slide.position) {
        this.slidePreview[slide.position] = slide;
      } else {
        this.slidePreview.push(slide);
      }
    });
  }

  updateCurrentPreview(data) {
    this.slidePreview[this.slidePreview.length - 1] = data;
  }
}

export const preview = {
  template: previewTemplate,
  controller: [
    '$state',
    '$scope',
    '$transitions',
    '$rootScope',
    'preview',
    Preview,
  ],
};
