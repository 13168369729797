export default function presentation($rootScope, $googleImages, $pptPresentationService) {
  const TYPE_PROFESSIONAL = 'I have to be present at work. It has to be almost like a document.';
  const TYPE_VISUAL = 'I have to communicate something. I want it a little more visual.';

  const TYPE_BASE = 'Basic';
  const TYPE_INSPIRATION = 'Inspirational';
  const TYPE_TEXTUAL = 'Textual';

  const YES = 'Yes';
  const NO = 'No';

  const slides = [];

  const service = {
    getFirstQuestion,
    getTitleQuestion,
    getSubtitleQuestion,
    getImageSearchQuestion,
    processAnswer,
  };
  return service;

  function getFirstQuestion() {
    return {
      name: 'type',
      question: 'What kind of presentation are you going to need?',
      answers: [TYPE_BASE,
        TYPE_INSPIRATION,
        TYPE_TEXTUAL],
      multiple: false,
    };
  }

  function getTitleQuestion() {
    return {
      name: 'title',
      question: 'What\'s the title for your slide?',
    };
  }

  function getSubtitleQuestion() {
    return {
      name: 'subtitle',
      question: 'What\'s the subtitle for your slide?',
    };
  }

  function getTextQuestion() {
    return {
      name: 'text',
      question: 'What\'s the text for your slide?',
    };
  }

  function getDefaultLogoQuestion() {
    return {
      name: 'default-logo',
      question: 'Would you like to display the default logo?',
      answers: [YES, NO],
      multiple: false,
    };
  }

  function getImageSearchQuestion() {
    return {
      name: 'image-search',
      question: 'What brand do you want? I\'ll look it up for you.',
    };
  }

  function getImageQuestion(images) {
    return {
      name: 'image',
      question: 'Choose an image...',
      answers: images,
      multiple: false,
    };
  }

  function getExportPresentationQuestion() {
    return {
      name: 'export-presentation',
      question: 'Would you like to export presentation?',
      answers: [YES, NO],
      multiple: false,
    };
  }

  function getNextStepQuestion() {
    return {
      name: 'next-step',
      question: 'Would you like add new slide?',
      answers: [YES, NO],
      multiple: false,
    };
  }

  /**
   * Process user answer to bot question.
   *
   * @param dto
   * @returns void
   */
  function processAnswer(dto) {
    switch (dto.tagName) {
      case 'type':
        processTypeAnswer(dto.value[0]);
        break;
      case 'title':
        processTitleAnswer(dto.value);
        break;
      case 'subtitle':
        processSubtitleAnswer(dto.value);
        break;
      case 'text':
        processTextAnswer(dto.value);
        break;
      case 'default-logo':
        processDefaultLogoAnswer(dto.value[0]);
        break;
      case 'image-search':
        processSearchImageAnswer(dto.value);
        break;
      case 'image':
        processImageAnswer(dto.value[0]);
        break;
      case 'next-step':
        processNextStepAnswer(dto.value[0]);
        break;
      case 'export-presentation':
        processExportPresentationAnswer(dto.value[0]);
        break;
      default:
        return;
    }
  }

  function processTypeAnswer(answer) {
    switch (answer) {
      case TYPE_PROFESSIONAL:
      case TYPE_VISUAL:
      case TYPE_BASE:
        createSlide('basic');
        $rootScope.$emit('ppt-bot:speak-bot', { message: 'Great! Let\'s start.' });
        $rootScope.$emit('ppt-bot:ask', getTitleQuestion());
        break;
      case TYPE_INSPIRATION:
        createSlide('inspirational');
        $rootScope.$emit('ppt-bot:speak-bot', { message: 'Great! Let\'s start.' });
        $rootScope.$emit('ppt-bot:ask', getTextQuestion());
        break;
      case TYPE_TEXTUAL:
        createSlide('textual');
        $rootScope.$emit('ppt-bot:speak-bot', { message: 'Great! Let\'s start.' });
        $rootScope.$emit('ppt-bot:ask', getTitleQuestion());
        break;
      default:
        $rootScope.$emit('ppt-bot:speak-bot', { message: 'I don\'t know how to do it, but I\'m studying.' });
        return;
    }
  }

  function processTitleAnswer(answer) {
    addDataToSlide('title', answer);

    $rootScope.$emit('ppt-bot:speak-bot', { message: 'Sounds nice.' });

    switch (getSlideType()) {
      case 'textual':
        $rootScope.$emit('ppt-bot:ask', getTextQuestion());
        break;
      case 'basic':
      default:
        $rootScope.$emit('ppt-bot:ask', getSubtitleQuestion());
        break;
    }
  }

  function processSubtitleAnswer(answer) {
    addDataToSlide('subtitle', answer);

    $rootScope.$emit('ppt-bot:speak-bot', { message: 'Good.' });

    switch (getSlideType()) {
      case 'basic':
        $rootScope.$emit('ppt-bot:ask', getImageSearchQuestion());
        break;
      case 'textual':
        $rootScope.$emit('ppt-bot:ask-multiple', getNextStepQuestion());
        break;
      default:
        break;
    }
  }

  function processTextAnswer(answer) {
    addDataToSlide('text', answer);

    $rootScope.$emit('ppt-bot:speak-bot', { message: 'Great.' });

    switch (getSlideType()) {
      case 'textual':
        $rootScope.$emit('ppt-bot:ask-multiple', getDefaultLogoQuestion());
        break;
      case 'inspirational':
        $rootScope.$emit('ppt-bot:ask-multiple', getNextStepQuestion());
        break;
      default:
        break;
    }
  }

  function processDefaultLogoAnswer(answer) {
    addDataToSlide('defaultLogo', answer === YES);

    switch (answer) {
      case YES:
        $rootScope.$emit('ppt-bot:speak-bot', { message: 'Ok, I\'ll add it.' });
        break;
      case NO:
      default:
        $rootScope.$emit('ppt-bot:speak-bot', { message: 'As you wish.' });
        break;
    }

    switch (getSlideType()) {
      case 'basic':
      case 'textual':
        $rootScope.$emit('ppt-bot:ask-multiple', getNextStepQuestion());
        break;
      default:
        break;
    }
  }

  function processExportPresentationAnswer(answer) {
    switch (answer) {
      case YES:
        $rootScope.$emit('ppt-bot:speak-bot', { message: 'Ok, I\'ll add it.' });
        // TODO: call ppt service
        $pptPresentationService.download(slides);
        break;
      case NO:
      default:
        $rootScope.$emit('ppt-bot:speak-bot', { message: 'As you wish.' });
        break;
    }
  }

  function processNextStepAnswer(answer) {
    switch (answer) {
      case YES:
        $rootScope.$emit('ppt-bot:speak-bot', { message: 'Ok, I\'ll add it.' });
        $rootScope.$emit('ppt-bot:ask-multiple', getFirstQuestion());
        break;
      case NO:
      default:
        $rootScope.$emit('ppt-bot:speak-bot', { message: 'As you wish.' });
        // TODO aggiungi slide di ringraziamento
        $rootScope.$emit('ppt-bot:ask-multiple', getExportPresentationQuestion());
        break;
    }
  }

  function processSearchImageAnswer(answer) {
    console.log('processSearchImageAnswer');
    $rootScope.$emit('ppt-bot:speak-bot', { message: `Searching for "${answer}", please wait...` });
    $googleImages.search(answer)
      .then((images) => {
        console.log('images', images);
        if (images.length > 0) {
          const thumb = images.reduce((accumulator, value) => {
            accumulator.push(value.thumbnail.url);
            return accumulator;
          }, []);
          const question = getImageQuestion(thumb);
          console.log('question', question);
          $rootScope.$emit('ppt-bot:ask-images', question);
        } else {
          $rootScope.$emit('ppt-bot:speak-bot', { message: `Sorry, I can't find any images for ${answer}. Try again with another query!` });

          $rootScope.$emit('ppt-bot:ask', getImageSearchQuestion());
        }
      });
  }

  function processImageAnswer(answer) {
    addDataToSlideImage('image', answer)
      .then(() => {
        $rootScope.$emit('ppt-bot:speak-bot', { message: 'Ok, adding it to the slide.' });
        switch (getSlideType()) {
          case 'basic':
            $rootScope.$emit('ppt-bot:ask-multiple', getNextStepQuestion());
            break;
          default:
            break;
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  function createSlide(type) {
    slides.push({ type });

    $rootScope.$emit('ppt-slide:created', getCurrentSlide());
  }

  function getCurrentSlide() {
    return slides[slides.length - 1];
  }

  function getSlideType() {
    const slide = getCurrentSlide();
    return slide.type;
  }

  function addDataToSlide(field, value) {
    const slide = getCurrentSlide();
    slide[field] = value;
    $rootScope.$emit('ppt-slide:updated', getCurrentSlide());
  }

  function addDataToSlideImage(field, value) {
    const slide = getCurrentSlide();

    return new Promise((resolve, reject) => {
      slide[field] = value;
      $rootScope.$emit('ppt-slide:updated', getCurrentSlide());
      toDataUrl(value, (myBase64, w, h) => {
        console.log(myBase64); // myBase64 is the base64 string
        const imagedata = 'imagedata';
        slide[imagedata] = myBase64;
        $rootScope.$emit('ppt-slide:updated', getCurrentSlide());
        resolve();
      });
    });
  }

  function toDataUrl(url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
      const reader = new FileReader();
      reader.onloadend = () => {
        callback(reader.result, 0, 0);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }
}
