import GoogleImages from 'google-images';

export default function googleImages() {
  const service = {
    search,
  };
  return service;

  function search(query) {
    console.log(process.env.CSE_ID);
    console.log(process.env.CSE_KEY);
    const client = new GoogleImages(process.env.CSE_ID, process.env.CSE_KEY);
    return client.search(query);
  }
}
