import creatortemplate from './creator.tpl.html';

export class Creator {
  constructor($state, $scope, $animate, $presentation, $rootScope, $window) {
    Object.assign(this, {
      $state,
      $scope,
      $animate,
      $presentation,
      $rootScope,
      $window,
    });
  }

  $onInit() {
    this.$rootScope.$on('ppt-bot:created', () => {
      // Greet the user.
      this.$rootScope.$emit('ppt-bot:speak-bot', { message: 'Welcome! I will help you with your creation.' });

      const question = this.$presentation.getFirstQuestion();
      this.$rootScope.$emit('ppt-bot:ask-multiple', question);
    });

    this.$rootScope.$on('ppt-bot:answered', (event, dto) => {
      this.$presentation.processAnswer(dto);
      // this.$rootScope.$emit('ppt-bot:speak-bot', { message });
    });

    this.$rootScope.$emit('ppt-bot:create', {});
  }
}

Creator.$inject = ['$state'];

export const creator = {
  template: creatortemplate,
  controller: [
    '$state',
    '$scope',
    '$animate',
    'presentation',
    '$rootScope',
    '$window',
    Creator,
  ],
};
