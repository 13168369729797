import html2canvas from 'html2canvas';

export default function preview($rootScope, $q) {
  const service = {
    createPreview,
    updatePreview,
  };
  return service;

  function createPreview(slide) {
    const tile = { ...slide };

    generateCanvas()
      .then((canvas) => {
        tile.canvas = canvas.toDataURL();

        $rootScope.$emit('ppt-preview:created', tile);
      });
  }

  function updatePreview(slide) {
    const tile = { ...slide };

    generateCanvas()
      .then((canvas) => {
        console.debug('canvas', canvas);
        tile.canvas = canvas.toDataURL();

        $rootScope.$emit('ppt-preview:updated', tile);
      });
  }

  function generateCanvas() {
    const deferred = $q.defer();

    const slide = document.querySelector('.ppt-slide');
    // const element = angular.element(document.querySelector('.slide'));
    // const height = element[0].offsetHeight;
    // console.log('height =>', height);
    if (typeof slide !== 'undefined') {
      html2canvas(slide,
        {
          useCORS: true,
          // height: '370px',
          // width: '661px',
          onclone: (doc) => {
            const slideExt = doc.querySelector('.ppt-slide');
            const slideInt = doc.querySelector('.slide');
            angular.element(slideExt)[0].style.maxHeight = 'max-content';
            angular.element(slideExt)[0].style.minHeight = 'fit-content';
            
            
            // angular.element(slideExt)[0].style.height = '100%';
            // angular.element(slideInt)[0].style.height = '100%';
            // angular.element(slideInt)[0].style.paddingBottom = '0';
            // angular.element(slideInt)[0].style.paddingRight = '56%';
            // angular.element(slideInt)[0].style.width = '0';
            // angular.element(slideInt)[0].style.marginTop = '0';
            // angular.element(slideInt)[0].style.marginBottom = '0';
            // angular.element(slideInt)[0].style.marginLeft = 'auto';
            // angular.element(slideInt)[0].style.marginRight = 'auto';
          },
        })
        .then((canvas) => {
          deferred.resolve(canvas);
        });
    }

    return deferred.promise;
  }
}
